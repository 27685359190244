import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) { 
    let lang = localStorage.getItem('lang') || 'en';
    this.translateService.setDefaultLang(lang);
  }

  ngOnInit() {
  }

  goToRoute(url, type?: any) {
    if (!type) {
      this.router.navigate([url]);
    } else {
      this.router.navigate([url], { queryParams: { type } })
    }
  }

}
