import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rider-dumb',
  templateUrl: './rider-dumb.component.html',
  styleUrls: ['./rider-dumb.component.css']
})
export class RiderDumbComponent {

  constructor() { }

}
