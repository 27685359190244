import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.css']
})
export class AuthFooterComponent implements OnInit {
  about:any;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) { 
    let lang = localStorage.getItem('lang') || 'en';
    this.translateService.setDefaultLang(lang);   
    }

  ngOnInit() {}

  goToRoute(url, routetype?: string) {
    if (!routetype) {
      this.router.navigate([url]);
    } else {
      this.router.navigate([url], { queryParams: { type: routetype } })
    }
  }

}
