export const env = {
  // facebookAppId: '816316902129492',
  // facebookAppSecret: '5cda8af94472e948a676e7b232d87cb8',
  // facebookAppSecret: '4f5cc47fa43c01d7130899db2d474593',

  // dev key's
  facebookAppId: '1955031754683671',
  facebookAppSecret: '2018ab96a67afe79b2a37c64ba960aa9',
  /* Reciente */
  // googleClientId: '529902784674-otnb23d0kmibkj4sqgk00atsa9ktt9nn.apps.googleusercontent.com',

  /* Para funcionar con el servicio legacy  */
  googleClientId: '348150202787-sgcbou6qfiktq80m4fvca7svii6qm4q2.apps.googleusercontent.com',  
  // googleApiKey: 'AIzaSyCK1cwNv5gtuumRF41-Yx08eFUEM6NvvdY'

  // live key's
  //facebookAppId: '287989212619118',
  //googleClientId: '911236294503-kmrb916vkh7fkr0i81dk3rhjl9i0h4sa.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyDD0EneEAjgmo4c4oxcEBh9UyOcTwTp2L0'
};
